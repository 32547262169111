var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "listItem"
    ? _c(
        "v-list-item",
        [
          _c(
            "v-list-item-title",
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.textToCopy,
                      expression: "textToCopy",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onSuccess,
                      expression: "onSuccess",
                      arg: "success",
                    },
                  ],
                  staticClass: "justify-start",
                  attrs: { color: "secondary", text: "", block: "" },
                  on: {
                    click: function ($event) {
                      _vm.itemClicked = true
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.icon)),
                  ]),
                  _vm._v(" " + _vm._s(_vm.buttonName) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _vm.type === "button"
                    ? _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.textToCopy,
                                  expression: "textToCopy",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onSuccess,
                                  expression: "onSuccess",
                                  arg: "success",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:error",
                                  value: _vm.onFail,
                                  expression: "onFail",
                                  arg: "error",
                                },
                              ],
                              class: _vm.buttonClass,
                              attrs: {
                                color: _vm.buttonColor,
                                text: _vm.isTextButton,
                                icon: _vm.iconButton,
                                disabled: _vm.disabled,
                                small: _vm.isSmall,
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm.showIcon
                            ? _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: _vm.isSmall,
                                    left: _vm.isTextButton,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.icon))]
                              )
                            : _vm._e(),
                          !_vm.iconButton
                            ? _c("span", [_vm._v(_vm._s(_vm.buttonName))])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }